.formModel {
  max-width: 915px;
  margin: 0 auto;
  background: #fff;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 50px;
  @include susy-breakpoint(bp(tablet)) {
    padding-right: 143px;
    padding-left: 143px;
  }

  &__out {
    // background-color: c(light-grey);
  }
  &__inner {
    padding-top: 16px;
    font-family: ff(ATF);
  }
  &__textbox {
    text-align: left;
    // padding: 10px 0 0;

    @include susy-breakpoint(bp(tablet)) {
      // padding: 16px 0 19px;
    }

    &-title {
      // font: normal normal medium 12px/17px Franklin Gothic ATF;
      font-size: 12px;
      line-height: 17px;
      font-weight: 500;
      letter-spacing: 0px;
      // @apply font-fga;

      @include susy-breakpoint(bp(tablet)) {
        // font-size: 32px;
        // line-height: 35px;
      }
    }

    &-text {
      // font-family: ff(Dor);
      padding-top: 10px;
      font-size: 12px;
      line-height: 17px;
      // @apply font-fga;
    }
  }

  &__textbox-inner {
    max-width: 560px;
    padding: 0 30px;
    margin: 0 auto;
  }

  & .common_sections {
    @include susy-breakpoint(bp(tablet)) {
      width: calc((52% - 28px));
    }
  }

  & .common_sections.special1 {
    @include susy-breakpoint(bp(tablet)) {
      width: 500px;
    }
  }

  & .common_sections.special2 {
    @include susy-breakpoint(bp(tablet)) {
      // width: 100%;
    }
  }

  &__clearBox {
    @include susy-clearfix();

    & .common_sections {
      @include susy-breakpoint(bp(tablet)) {
        float: left;
        margin-right: 25px;
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }

    & .common_sections.twoLine {
      @include susy-breakpoint(bp(tablet)) {
        padding-top: 10px;
      }
    }
  }

  &__upAndDown {
    padding: 20px 0 10px;
    font-family: ff(Am);
  }

  &__litBox {
    margin: -8px 0 10px 0;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.8;

    @include susy-breakpoint(bp(tablet)) {
      margin-bottom: 4px;
    }
  }

  & .recaptchaIpt {
    width: 0;
    height: 0;
    padding: 0;
    outline: 0;
    overflow: hidden;
    border: none;
  }

  &__sp {
    position: relative;
    margin-top: -8px;
  }

  & .g-recaptcha iframe {
    width: auto;
    height: auto;
  }

  &__likeIpt {
    background-color: #fff;
    height: 50px;
    line-height: 50px;
    position: relative;
    padding-left: 10px;
    border: solid 1px #000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: relative;
    & span {
      color: #aaaaaa;
      font-size: 18px;
    }
    span:last-child {
      position: absolute;
      right: 0;
      border-left:1px solid #000;
      padding: 0 15px;
    }
  }

  &__calendar {
    width: 16px;
    cursor: pointer;
  }

  & button.common-cta_btn {
    min-width: 140px;

    @include susy-breakpoint(bp(tablet)) {
      min-width: 210px;
    }
  }
  .width-moto {
    width: 80%;
    @media (max-width: 960px) {
      width: 100%;
    }
  }
  .width-contact {
    width: 100%;
    padding-top: 20px;
    p {
      text-align: left;
      // font: normal normal normal 12px/17px Franklin Gothic ATF;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0px;
      a {
        position: relative;
        cursor: pointer;
        color:#eb601d;
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-width: 10px 10px 0;
          border-style: solid;
          border-color: black transparent transparent;
          left: 50%;
          top: 27px;
          display: none;
          transform: rotate(180deg);
        }
      }
      .show {
        &::after {
          display: block;
        }
      }
    }
    .weight {
      font-weight: 500;
    }
  }
  .common_form-ipt::placeholder {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.12px;
    color: #aaaaaa;
  }
  .special2 {
    padding-bottom: 20px;
  }
  .checkbox-phone {
    width: 100%;
    label[for="usePhone"] {
      padding-bottom: 20px;
    }
    .common_sections {
      padding-top: 0;
      padding-bottom: 20px;
      display: none;
    }
  }
  .submit-left {
    text-align: left;
    .expanding {
      min-width: unset;
      padding: 12px 20px;
      color: #fff;
      font-family: ff(ATO);
      letter-spacing: normal;
    }
  }
  .pb {
    padding-bottom: 20px;
  }
  .common_form-place-hold {
    font-weight: 500;
    font-family: ff(ATF);
    letter-spacing: -0.12px;
    color: #aaaaaa;
  }
  .wp50 {
    margin-top: 20px;
  }
  .horilized {
    float: left;
    label[for="usePost"] {
      padding-bottom: 0;
    }
  }
  .license {
    // display: block;
    position: absolute;
    top:94px;
    // top: 82px;
    // @media (min-width: 768px) {
    //   top: -81px;
    // }
  }
  .form-after {
    .common_form-out-box {
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-width: 10px 10px 0;
        border-style: solid;
        border-color: black transparent transparent;
        left: 20%;
        top: 31px;
        transform: rotate(180deg);
        display: none;

        @media (max-width: 768px) {
          left: 10%;
        }
      }
    }
  }
  .show {
    .common_form-out-box {
      &::after {
        display: block;
      }
    }
  }

  .flex-checkbox {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .special2 {
      width: 50%;
      .common_form-ipt-container {
        width: 95%;
      }
      @media(max-width: 768px) {
        width: 100%;
        .common_form-ipt-container {
          width: 100%;
        }
      }
    }
    @media(max-width:768px) {
      div:first-child {
        padding-bottom: 0px;
      }
    }
  }
}

.saleForeError {
  color: #f60;
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 10px;
}

.common_sections.form_error .formModel__likeIpt {
  color: c(error);
  border-color: c(error);
  display: flex;
}

.common_form-loading {
  margin-left: 15px;
  display: none;
}

.popup {
  display: none;
  position: absolute;
  background-color: black;
  z-index: 3;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  bottom: auto;
  padding: 15px 20px;
  top: 55px;
  font-family: ff(ATF);
  text-align: left;
  @media (max-width: 376px) {
    top: 70px;
  }

  &__close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      transform: rotate(45deg);
      background-color: #f7f7f7;
      top: 50%;
      margin-top: -2px;
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  // &__footer-traggle {
  //   display: block;
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   border-width: 10px 10px 0;
  //   border-style: solid;
  //   border-color: black transparent transparent;
  //   left: 48%;
  //   top: -12px;
  //   bottom: -10px;
  // }

  &.license {
    width: 100%;
    bottom: auto;

    & .popup__footer-traggle {
      // border-width: 0 10px 10px;
      // border-style: solid;
      // border-color: transparent transparent #333;
      // bottom: auto;
      // top: -10px;
      // left: 90px;
    }

    & a {
      color: c(main);
    }
  }
  & a {
    cursor: pointer;
    text-decoration: underline;
  }
}
// .secondPop {
//   height: 191px;
// }

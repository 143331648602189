.footerError {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 40px 0;
    p:first-child {
        font: {
            size: 16px;
            family: ff(ATF);
        }
        letter-spacing: 0.75px;
        line-height: 21px;
        padding-bottom: 11px;
    }
    p:last-child {
        font: {
            size: 12px;
            weight: 500;
            family:ff(ATF)
        }
        letter-spacing: 0px;
        color: #989898;
    }
}

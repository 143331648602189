html {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  max-width: 1280px;
  margin: 0 auto !important;
  padding: 0;
}

a,
a:visited {
  outline: 0;
  text-decoration: none;
  color: c(black-c);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

::-ms-clear,
::-ms-reveal {
  display: none;
}

img {
  border: 0;
  vertical-align: middle;
  user-select: none;
}

table,
thead,
tbody,
tr,
td,
th {
  border: none;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
}

button,
input,
select,
textarea,
div {
  outline: none;
  border-radius: 0;
}

dd,
dt {
  margin: 0;
  padding: 0;
}

iframe {
  border-width: 0;
  display: block;
  width: 0;
  height: 0;
}

sub,
sup {
  position: relative;
  top: -4px;
  vertical-align: top;
}

.shareBar {
  text-align: center;
  border-top: 1px solid #eeeceb;
  border-bottom: 1px solid #eeeceb;
  &__title {
    padding-top: 20px;
    font: {
      size: 16px;
      weight: 500;
      family: ff(ATF);
    }
    line-height: 21px;
    // normal normal medium 16px/21px ;
    letter-spacing: -0.4px;
  }

  &__iconBox {
    padding-top: 15px;
    padding-bottom: 25px;
  }

  &__icon {
    width: 35px;
    height: 35px;
    display: inline-block;
    margin: 0 7.5px;
  }
}

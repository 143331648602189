@font-face {
  font-family: "Amerigo-roman";
  src: url("../fonts/AmerigoBT_Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Noto Sans";
  src: url("../fonts/NotoSans-Regular.woff") format("woff"),
    url("../fonts/NotoSans-Regular.woff2") format("woff2"),
    url("../fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
// @font-face {
//   font-family: "DinotRegular";
//   src: url("../fonts/DINOT-Regular.otf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: "DinotCondBold";
  src: url("../fonts/DINOT-CondBold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Antonio-Regular";
  src: url("../fonts/Antonio-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSerif-Regular";
  src: url("../fonts/NotoSerif-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "franklin-gothic-itc";
  src: url("../fonts/ITC-Franklin-Gothic-LT-Demi-Con-Regular.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

$fonts: (
  Am: "Amerigo-roman",
  // Dor: "DinotRegular",
  Docb: "DinotCondBold",
  An: "Antonio-Regular",
  ntr: "NotoSerif-Regular",
  ntsa: "Noto Sans",
  ATF: "franklin-gothic-atf",
  ATI: "franklin-gothic-itc",
  ATO: "ITC Franklin-Gothic-LT-Pro"
);

$fallback-fonts: "Noto Sans", Arial, sans-serif;

@function ff($the-font) {
  @return map-get($fonts, $the-font), $fallback-fonts;
}

.navigation {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background:#fff;
  img{
    width: 45px;
    height: 37px;
  }
}

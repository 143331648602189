.org-colored {
  /* stylelint-disable-next-line declaration-no-important */
  color: c(main) !important;
}

.uppercase-text {
  text-transform: uppercase;
}

.underLined {
  text-decoration: underline;
}

.paragraph__body-mid {
  font-size: 14px;
  line-height: 22px;
}


.home {
  position: relative;
  background-color: #eeeceb;
  padding: 35px 0;
  &__split-line {
    height: 0;
    border-top: 1px solid #eeeceb;
    margin: 0 auto;
    max-width: 915px;
  }

  &__switchBtn {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 999;
  }
  form {
    @media (max-width: 768px) {
      margin: 0 20px;
    }
  }
}

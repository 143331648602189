.noFound {
  min-height: 400px;
  position: relative;
  background: url("../images/png/noFound.jpg") center no-repeat;
  background-size: cover;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 44, 44, .8);
  }

  &__box {
    width: 310px;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: c(white-c);
  }

  &__title {
    text-align: center;
    padding-bottom: 20px;
    font-size: 42px;
    line-height: 48px;
    font-family: ff(Am);

    @include susy-breakpoint(bp(tablet)) {
      font-size: 48px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 22px;
    font-family: ff(Dor);
    padding: 0 10px;
    text-align: center;

    @include susy-breakpoint(bp(tablet)) {
      font-size: 18px;
      line-height: 24px;
    }

    & a {
      display: block;
      text-transform: uppercase;
      padding-top: 10px;
      font-size: 14px;
      letter-spacing: 1.6px;
    }
  }
}

.googleMap {
  position: relative;
  margin: 0 auto;
  max-width: 944px;
  padding-top: 17px;

  &__out {
    max-width: 915px;
    margin: 0 auto;
    background: #fff;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;

    @include susy-breakpoint(bp(tablet)) {
      padding-right: 143px;
      padding-left: 143px;
    }
  }

  &__auBox {
    padding: 30px 0 10px;

    & .common_form-type {
      font-family: ff(ATF);
    }

    & .common_form-radio-container {
      padding-top: 16px;
    }
  }

  &__textBox {
    text-align: left;
    font-family: ff(ATF);
    &-title {
      // font-family: ff(Am);
      // font-size: 26px;
      // line-height: 35px;
      // padding-bottom: 10px;
      // font:
      // normal normal medium 12px/17px Franklin Gothic ATF;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0px;
      color: #000000;
      // @apply font-fga;
      @include susy-breakpoint(bp(tablet)) {
        font-size: 12px;
        line-height: 17px;
      }
    }

    &-text,
    &-changeText {
      // font-family: ff(Dor);
      // font-size: 16px;
      // line-height: 22px;
      // font: normal normal normal 12px/17px Franklin Gothic ATF;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0px;
      padding-top: 12px;
      // @apply font-fga;
    }
  }

  &__clearBox {
    @include susy-clearfix();
    display: flex;
    text-align: left;
    font-family: ff(ATF);
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__dealerCard,
  &__dealerBox-btnOut {
    width: 45%;
    @media (max-width: 768px) {
      width: 151px;
    }
  }
  &__dealerCard {
    padding-right: 5%;
    @media (max-width: 768px) {
      padding-right: 0;
    }
  }
  &__dealerBox {
    padding: 20px 0;

    &-change {
      font-family: ff(ATF);
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
      color: #fa6600;
    }

    &-btn {
      padding-top: 20px;
      .common-cta_btn {
        display: inline-block;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 16px;
        width: 100%;
        text-align: center;
        letter-spacing: -0.16px;
        font-family: ff(ATF);
        min-height: unset;
        color: #fff;
        padding: 12px 17px;
        @media (min-width: 768px) {
          min-width: 132px;
          // margin: 20px 0;
          width: fit-content;
        }
      }
    }

    &-btnOut {
      // @include susy-breakpoint(bp(tablet)) {
      //   float: left;
      //   width: 360px;
      //   padding: 30px 0 0 85px;
      // }
    }
  }

  &__dealerCard {
    // margin: 30px 0 40px;
    // padding: 30px 53px 35px 20px;
    // // background-color: c(white-c);
    // color: c(black-c);

    // @include susy-breakpoint(bp(tablet)) {
    //   float: left;
    //   width: 360px;
    //   margin-top: 25px;
    //   padding: 25px 75px 30px 42px;
    //   margin-bottom: 0;
    // }
    @media (max-width: 768px) {
      padding-bottom: 20px;
    }
    &-title {
      font-family: ff(ATF);
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
    }

    &-text {
      padding-top: 8px;
      font-family: ff(ATF);
      font-size: 12px;
      line-height: 22px;
    }

    &-btn {
      // display: none;

      // @include susy-breakpoint(bp(tablet)) {
      //   padding-top: 31px;
      //   display: block;
      // }
      .common-cta_btn {
        display: inline-block;
        border: 1.5px solid black;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 36px;
        width: 100%;
        text-align: center;
        letter-spacing: -0.16px;
        font-family: ff(ATF);
        min-height: unset;
        @media (min-width: 768px) {
          min-width: 132px;
          // margin: 20px 0;
          width: fit-content;
          padding: 0px 17px;
        }
      }
    }
  }
  .googleMap__dealerCard-btn {
    padding-top: 20px;
    @media (max-width:768px){
      display: none;
    }
  }
  &__mapBox {
    padding: 15px 0 20px;

    &-text {
      font-family: ff(ATF);
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__bet {
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1.14px;

    @include susy-breakpoint(bp(tablet)) {
      float: left;
      width: 60px;
      line-height: 40px;
    }
  }

  &__searchBox {
    // margin-top: 5px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    @include susy-breakpoint(bp(tablet)) {
      float: left;
      // width: calc((136% - 60px) / 2);
      width: 80%;
    }

    &-out {
      @include susy-clearfix();
      max-width: 664px;
      @media (max-width: 960px) {
        max-width: unset;
      }
      & .common-cta_btn {
        @include susy-breakpoint(bp(tablet)) {
          margin-top: 5px;
          float: left;
          width: calc((100% - 60px) / 2);
        }
      }
    }

    & input {
      border: 1px solid #2c2c2c;
      border-radius: 4px;
      height: 50px;
      line-height: 50px;
      padding: 0 12px;
      width: 100%;
      padding-right: 45px;
    }
    & input::placeholder {
      // font: normal normal medium 12px/16px Franklin Gothic ATF;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      // @apply font-fga;
      letter-spacing: -0.12px;
      color: #aaaaaa;
    }
    span {
      position: absolute;
      top: 0;
      // left: 93.3%;
      height: 50px;
      display: inline-block;
      border-left: 1px solid #2c2c2c;
      // /* float: right;
    }
  }

  &__loader {
    display: none;
    position: absolute;
    top: 10px;
    right: 60px;
  }

  &__locator {
    padding: 18px 13px;
  }

  &__container {
    @include susy-clearfix();
    position: relative;
  }

  &__map {
    padding-bottom: 100%;
    background-color: c(dark-grey);

    @include susy-breakpoint(bp(tablet)) {
      padding-bottom: 0;
      width: 340px;
      height: 350px;
      float: right;
    }
  }

  &__info-box {
    background-color: #eee;

    @include susy-breakpoint(bp(tablet)) {
      width: calc(100% - 340px);
      height: 350px;
      float: right;
      overflow: hidden;
      overflow-y: scroll;
    }
  }

  &__info-list {
    cursor: pointer;
    @include susy-clearfix();
    display: block;
    width: 100%;
    border-top: solid 1px c(dark-grey);
    padding:30px 22px 23px 14px;
    background-color: c(bg);
    color: #000;

    @include susy-breakpoint(bp(tablet)) {
      border-top: none;
      border-bottom: solid 1px c(line);
      padding: 13px 15px 15px;
    }

    &.active {
      background-color: c(main);
      border: solid 2px c(main);

      @include susy-breakpoint(bp(tablet)) {
        border: solid 1px c(main);
      }

      & .googleMap__detail-title,
      & .googleMap__distance {
        color: c(black-c);
      }

      & .googleMap__img-count {
        background: url("../images/png/Map-Pin-3.png") no-repeat;
        background-size: cover;
        color: c(white-c);
      }

      & .org-colored {
        color: c(white-c) !important;
      }
    }
  }

  &__img-box {
    float: left;
    position: relative;
  }

  &__img-count {
    width: 30px;
    height: 47.1px;
    background: url("../images/png/Map-Pin.png") no-repeat;
    background-size: cover;
    line-height: 28px;
    font-size: 14px;
    text-align: center;
    color: c(black-c);
    font-family: ff(ATF);
    font-weight: 600;
  }

  &__detail-box {
    position: relative;
    float: right;
    width: calc(100% - 60px);
  }

  &__detail-title {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    font-family: ff(ATF);
    text-align: left;
  }

  &__detail-address,
  &__detail-phone {
    font-size: 11px;
    line-height: 19px;
    margin-top: 4px;
    max-width: 140px;
    font-family: ff(ATF);
    text-align: left;
  }

  &__distance {
    font-size: 10px;
    line-height: 20px;
    font-family: ff(ATF);
    margin-top: 5px;
  }

  &__detail-phone {
    margin-top: 10px;
  }

  &__isShowBox {
    font-size: 14px;
    line-height: 22px;
    font-family: ff(ATF);
    margin-top: 5px;
  }

  &__showMore {
    font-size: 15px;
    line-height: 23px;
    margin-top: 23px;
    font-family: ff(ATF);
  }
}

.el_GR {
  & .googleMap__dealerBox-btnOut {
    @include susy-breakpoint(bp(tablet)) {
      padding: 30px 0 0 85px;
    }
  }
}

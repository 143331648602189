.hero {
  text-align: center;
  font-family: ff(ATF);
  &__inner {
    position: relative;
  }

  &__title {
    font: {
      size: 37px;
      weight: bold;
    }
    line-height: 37px;
    letter-spacing: 0px;
    padding-bottom: 15px;
  }

  &__text {
    p {
      font: {
        size: 16px;
      }
      line-height: 21px;
      color: #000;
      letter-spacing: -0.4px;
      &:last-child {
        padding-top: 15px;
        padding-bottom: 35px;
      }
    }
  }

  &__textBox {
    font-size: 16px;
    line-height: 22px;
    color: #2c2c2c;
    padding-bottom: 30px;
  }
}

.common_sections {
  @include susy-clearfix();
  position: relative;
  padding-top: 20px;

  &.st2 {
    padding-top: 20px;
  }

  &.st3 {
    padding-top: 10px;
  }
}

.common_form {
  &-textarea {
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    padding: 10px 14px;
    resize: none;
    margin: 2px 0;
    font-size: 16px;
    line-height: 18px;
  }

  &-name {
    // font-size: 13px;
    // line-height: 22px;
    padding-bottom: 15px;
    // font: normal normal medium 12px/17px Franklin Gothic ATF;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    // @apply font-fga;
    letter-spacing: 0px;

    @include susy-breakpoint(bp(tablet)) {
      // line-height: 18px;
    }
  }

  &-name.last {
    margin-bottom: 10px;
  }

  &-ipt,
  &-ipt-container {
    display: block;
    width: 100%;
    padding: 0 15px;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    border: solid 1px #2c2c2c;
    border-radius: 4px;
  }

  &-ipt.small,
  &-ipt.middle {
    display: inline-block;
    width: 30px;
    text-align: center;
    border: none;
    padding: 0 2px;
    height: 42px;
    line-height: 43px;
    position: relative;
  }

  &-ipt.middle {
    text-align: left;
    width: 48px;
  }

  &-ipt-container {
    position: relative;
    padding: 15px 18px 15px;
    background-color: c(white-c);
    border-radius: 4px;
    border: 1px solid #000;
  }

  &-select {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    border-radius: 0;
    border: solid 1px #b5b5b5;
  }

  &-place-hold {
    text-align: left;
    font-family: ff(ATF);
    font-size: 16px;
    line-height: 1;
    color: #000;
    height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: url("../images/svg/arrow_black.svg") no-repeat center right 0;
  }

  &-place-hold .icon {
    position: absolute;
    right: 16px;
    top: 50%;
    margin-top: -10px;
    color: c(black-c);
  }

  &-label {
    display: inline-block;
    position: relative;
    // padding: 9px 20px 10px 30px;
    padding-left: 35px;
    cursor: pointer;

    & .common_form-type {
      // padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .horilized &-label {
    display: block;
    padding-bottom: 20px;
  }

  &-radio {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }

  &-type {
    line-height: 17px;
    vertical-align: top;
    font-size: 12px;
    // @apply font-fga;
    letter-spacing: 0px;
  }

  &-type.st2 {
    font-size: 14px;
    line-height: 28px;
  }

  &-out-box {
    width: 25px;
    height: 25px;
    position: absolute;
    border: solid 1px #2c2c2c;
    top: 0;
    left: 0;
    background-color: c(white-c);

    &.round {
      border-radius: 15px;
    }

    &.focusedOn {
      border-color: rgba(131, 192, 253, 0.5);
    }
  }

  &-inner-box {
    width: 23px;
    height: 23px;
    background-color: c(main);
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    transition: opacity 0.35s;

    &.round {
      border-radius: 10px;
    }
  }

  &-radio:checked + &-out-box &-inner-box {
    opacity: 1;
  }

  &-hidden-ipt {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
  }

  &-line {
    height: 1px;
    background-color: c(dark-grey);
    margin: 30px auto 10px;

    @include susy-breakpoint(bp(tablet)) {
      max-width: 904px;
      margin: 30px 0 10px;
    }
  }

  &-line.st2 {
    margin-top: 25px;
  }

  &__process {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 8px;
    background-color: c(bodyLit);
    z-index: 1;
  }

  &__bar {
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background-color: c(main);
    transition: all 0.9s ease-in-out;

    &.min {
      width: 33%;
    }

    &.active {
      width: 66%;
    }

    &.max {
      width: 100%;
    }
  }
}

.common-cta_btn {
  // transition: all .5s ease-in-out;
  position: relative;
  display: inline-block;
  font-family: ff(ATF);
  font-size: 16px;
  //line-height: 26px;
  line-height: 18px;
  min-height: 48px;
  text-transform: uppercase;
  color: c(black-c);
  background-color: c(main);
  cursor: pointer;
  text-align: left;
  padding: 15px 43px 15px 20px;
  border: none;
  letter-spacing: 1.6px;

  & img,
  & .icon {
    position: absolute;
    width: 18px;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  & .icon-go-right {
    transform: translateY(0);
    margin-top: -6px;
  }

  &:hover {
    background-color: #d3561a;
  }

  &.blocked {
    display: block;
    width: 100%;
    text-align: center;
    padding: auto;
    max-width: 335px;
  }

  &.bordered {
    min-height: 52px;
    border: solid 2px c(black-c);
    background-color: transparent;

    &:hover {
      color: c(white-c);
      background-color: c(black-c);
    }
  }

  &.dark {
    background-color: c(white-c);

    &:hover {
      background-color: c(lineLit);
    }
  }

  &.dark.bordered {
    color: c(white-c);
    background-color: transparent;
    border: solid 2px c(white-c);

    &:hover {
      color: c(black-c);
      background-color: c(white-c);
    }
  }
}

p.error,
div.error {
  display: none;
  font-size: 14px;
  line-height: 18px;
  padding-top: 5px;
}

.common_sections.form_error {
  color: c(error);
}

.common_sections.form_error .error {
  display: block;
  text-align: left;
}

.common_sections.form_error.special .error,
.common_sections.form_error .error.special {
  display: none;
}

.common_sections.form_error.special .error.special {
  display: block;
}

.common_form-ipt-container .common_form-place-hold {
  color: c(line);
  span {
    // font: normal normal medium 12px/16px Franklin Gothic ATF;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.12px;
    color: #aaaaaa;
  }
}

.common_form-ipt-container .common_form-place-hold.used {
  color: c(black-c);
}

.common_sections.form_error .common_form-ipt,
.common_sections.form_error .common_form-ipt-container,
.common_sections.form_error .common_form-ipt-container .common_form-place-hold,
.common_sections.form_error .common_form-ipt-container .common_form-place-hold.used {
  color: c(error);
  border-color: c(error);
}

.common_sections.form_error .common_form-ipt-container {
  border: solid 1px;
}

.common_sections.form_error ::-webkit-input-placeholder {
  color: c(error);
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder {
  font-size: 14px;
  font-family: ff(ATF);
  color: c(light-grey);
}

input:-moz-placeholder,
input::-moz-placeholder {
  opacity: 1;
}

.common_sections.form_error .common_form-textarea {
  border-color: c(error);
}

.common_form-radio-container {
  // padding-top: 10px;s
  text-align: left;
}

.common_sections.form_error .common_form-radio-container {
  color: c(error);
}

.dn {
  display: none;
}

.pt10 {
  padding-top: 10px;
}

.ml5 {
  margin-left: 5px;
}

//animation

@keyframes bigger {
  0% {
    opacity: 0;
    transform: scaleY(0.001);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

label.common_form-name {
  display: block;
  color: #2c2c2c;
  text-align: left;
}

.common_sections.special .common_form-name {
  font-size: 16px;
}

button[type="submit"]:disabled {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: not-allowed;
  }
}

input:placeholder-shown,
input:placeholder-shown {
  font-family: ff(ATF) !important;
  font-weight: 500;
}

.white-bg {
  background-color: #fff !important;
}

// .wp50 {
//   @include susy-breakpoint(bp(tablet)) {
//     width: calc(50% - 28px);
//   }
// }

.en_AU {
  & .horilized .common_form-label {
    padding-bottom: 0;
  }
}

.cookieModel {
  position: fixed;
  transition: all 0.35s ease-in-out;
  background-color: #fff;
  overflow: hidden;
  max-height: 0;
  width: 100%;
  bottom: 0;
  z-index: 5;
  font-family: DinotRegular, Arial, sans-serif;
  max-width: 1280px;
  &__outer {
    position: relative;
    margin: 0 auto;
    display: flex;
    @media (min-width: 960px) {
      padding: 30px 120px;
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    border: none;
    background-color: transparent;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    right: 30px;
    order: 1;
    border: none;
    background: transparent;
    @media (min-width: 960px) {
      width: 40px;
      height: 40px;
      line-height: 40px;
      right: 60px;
    }

    .icon-close {
      width: 24px;
      height: 24px;
      @media (min-width: 960px) {
        width: 40px;
        height: 40px;
      }
    }
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 960px) {
      text-align: center;
      max-width: 900px;
      margin: 0 auto;
    }
  }
}
.en_EU {
  .footer__share-container {
    display: none;
  }
  .footer_links-container{padding-top: 10px;}
}

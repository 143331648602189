.bg-form {
    width: 100%;
    height: 100%;
    &-error {
        width: 100%;
    }
    @media (min-width: 768px) {
        margin-top: 0px;
    }
}
.formdemo-formContainer {
    margin: 35px 20px;
    @media (min-width: 768px) {
        width: 100%;
        max-width: 915px;
        margin-left: auto;
        margin-right: auto;
        min-height: calc(100vh - 333px);
    }
}
.formdemo-formWrapper {
    width: 100%;
    background: white;
    position: relative;
    padding-top: 50px;
    padding-bottom: 60px;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 768px) {
        min-height: unset;
        max-width: 1280px;
        padding-right: 15.8%;
        padding-left: 15.8%;
    }
}
.formdemo-header {
    text-align: center;
    width: 100%;
    font-family: ff(ATF);
    h1 {
        line-height: 46px;
        padding-bottom: 15px;
        font-size: 36px;
        font-weight: 700;
    }
    p {
        letter-spacing: -0.4px;
        font-size: 16px;
    }
}
a {
    &.formdemo_link {
        color: #fa6600;
        display: inline-block;
        position: relative;
        font-size: 16px;
        font-family: ff(ATF);
        padding-top: 20px;
        text-decoration: revert;
    }
}

.page-background {
  position: fixed;
  width: 1280px;
  height: 100vh;
  background: #eeeceb;
  background-size: cover;
  top: 0;
  z-index: -1;
}
.thanks {
  display: flex;
  // background: #eeeceb;
  &-container {
    max-width: 915px;
    width: 100%;
    margin: 35px auto;
    background: #fff;
    @media (max-width: 768px) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  &-content {
    padding: 60px 15.8%;
    @media (max-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &__box {
    background-color: #f2f0ef;
    margin-bottom: 70px;

    @include susy-breakpoint(bp(tablet)) {
      margin-bottom: 0;
    }

    &-inner {
      margin: 0 auto;
      max-width: 900px;
      @include susy-clearfix();
      padding: 30px 30px 70px;
      position: relative;

      @include susy-breakpoint(bp(tablet)) {
        padding: 105px 30px 50px;
      }
    }
  }

  // &__back {
  //   position: absolute;
  //   width: 100%;
  //   top: 306px;
  //   bottom: 0;
  //   left: 0;
  //   background-color: #f2f0ef;
  // }

  &__au {
    padding: 60px 0 30px;
    font-size: 14px;
    line-height: 22px;
    font-family: ff(ATF);
    color: #666;
  }
  strong {
    font-weight: normal;
  }
}

.home__form {
  & .thanks__au {
    display: block;
    padding: 0;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 17px;
    color: #000;
    text-align: left;
    font-family: ff(ATF);
    max-width: 619px;
  }
}

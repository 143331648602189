.bikeInfoCard {
  margin-top: 26px;
  &__inner {
    display: flex;
    border-bottom: 1px solid #eeeceb;
    padding-bottom: 30px;
    font-family: ff(ATF);
    @media (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 20px;
    }
  }
  &__dealer,
  &__bike {
    width: 50%;
    @media(max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }
  &__dealer {
    padding-right: 10px;
    &-title {
      font: {
        size: 16px;
        weight: 500;
      }
      line-height: 21px;
      letter-spacing: -0.4px;
    }
    &-email {
      letter-spacing: 0px;
      color: #fa6600 !important;
      padding-bottom: 20px;
      display: inline-block;
      width: 100%;
      padding-top: 20px;
    }
    .title-pt {
      padding-top: 26px;
    }
    .text-pt {
      padding-top: 8px;
    }
    .bikeInfoCard__body-link{
      @media (max-width: 768px){
        display:block;
        width: 100%;
      }
    }
  }

  &__bike {
    border:1px solid #eeeceb;
    @media(max-width: 768px) {
      margin-top: 20px;
    }
    &-img {
      width: 100%;
      height: auto;
      object-fit: cover;
      padding: 0 8px;
    }
  }

  &__btnBox {
  }

  &__body {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    font-family: ff(ATF);
    @media (min-width: 768px) {
      justify-content: space-between;
      flex-direction: row;
    }
    @media (max-width: 768px) {
      margin-top: 20px;
    }
    &-left {
      flex-basis: 100%;
      @media (min-width: 768px) {
        flex-basis: 48%;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &-right {
      flex-basis: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      @media (min-width: 768px) {
        flex-basis: 48%;
        text-align: left;
      }
    }
    &-title {
      letter-spacing: -0.4px;
      line-height: 20px;
      font-weight: 500;
      // @apply font-fga font-medium text-base;
      @media (max-width: 768px) {
        padding-top: 16px;
      }
    }
    &-desc {
      margin-top: 12px;
      font-size: 12px;
      line-height: 16px;
      // @apply font-fga font-normal;
    }

    &-link {
      margin: 20px auto 0;
      display: inline-block;
      border: 1.5px solid black;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 36px;
      width: 100%;
      text-align: center;
      letter-spacing: -0.16px;
      font-family: ff(ATF);
      // @apply font-fgs font-normal;
      @media (min-width: 768px) {
        min-width: 132px;
        margin: 20px 0;
        width: fit-content;
        padding: 0px 17px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

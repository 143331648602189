.footer {
  font-family: ff(ATF);
  background-color: #000;
  text-align: center;
  color: c(mid-grey);

  &__warp {
  //  @apply font-fgs;
  font-family: ff(ATF);
   display: flex;
   padding-top: 40px;
   padding-bottom: 14px;
   justify-content:center;
   p{
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    // padding-top: 40px;
   }
   @media(max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
     p {
       width: 100%;
     }
   }
  }

  &_title {
    color: #fff;
  }
  &_color {
    color: #FA6600;
  }
  &__share-container {
    padding-top: 10px;
    padding-bottom: 10px;

    & a {
      display: inline-block;
      padding: 0 10px;

      & img {
        height: 23px;
      }

      & img.footer__icon-twitter {
        height: 21px;
      }
    }
  }

  &_links-container {
    & a {
      text-align: center;
      font: {
        weight: bold;
        size: 12px;
      }
      line-height: 16px;
      letter-spacing: 0.55px;
      color: #989898;
      text-transform: uppercase;
      opacity: 1;
    }
    @media (max-width: 768px) {
      transform: scale(0.9);
    }
  }

  &_register-container {
    padding-top: 17px;
    padding-bottom: 36px;
    font: {
      weight: 500;
      size: 12px;
    }
    line-height: 13px;
    letter-spacing: 0px;
    color: #989898;
  }

  & .common_form-ipt-container {
    //max-width: 350px;
    margin: 0 auto;
    border: none;
    text-align: center;
    background-color: transparent;
    padding: 0;
    position: relative;
    max-width: 230px;
  }

  & .common_form-select {
    position: relative;
    min-width: 230px;
    -webkit-appearance: none;
    height: 100%;
    width: 100%;
    background-size: 5px;
    border: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }

  & .common_sections {
    padding-top: 20px;
  }

  & .common_form-place-hold {
    border: 1px solid white;
    border-radius: 4px;
    width: 100%;
    font-size: 13px;
    text-transform: uppercase;
    color: white;
    height: 45px;
    line-height: 45px;
    padding: 0 17px;
    letter-spacing: -0.13px;
    background: url("../assets/images/svg/arrow_white.svg") no-repeat center right 12px;
    background-size: 12px;
    font-weight: 400;
    font-family: ff(ATF);
    & .icon {
      position: relative;
      color: c(main);
      right: auto;
      top: auto;
      margin: -5px 0 0 5px;
    }
  }
}

.forForms {
  @include susy-breakpoint(bp(tablet)) {
    & .footer {
      width: 66%;
      left: 34%;
    }
  }

  &.grey {
    background-color: c(bg);
  }
}

.splash-background {
  position: fixed;
  width: 1280px;
  height: 100vh;
  background: url("../images/png/trbf-landing.jpg");
  background-size: cover;
  top: 0;
  z-index: -1;
}
.splash {
  background: #fff;
  max-width: 915px;
  margin: 0 auto;
  margin-top: 95px;
  // padding-top: 50px;
  text-align: left;
  &__cont {
    // background-color: #f2f0ef;
    padding-bottom: 45px;
    // font-family:;
    @media (max-width:768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__inner {
    // max-width: 470px;
    // margin: 0 auto;
    // padding: 0 22px;
    // padding-right: 20px;
    // padding-left: 20px;
    // @apply font-fga;
    @include susy-breakpoint(bp(tablet)) {
      padding-right: 143px;
      padding-left: 143px;
    }
  }

  &__section {
    padding-top: 15px;

    &.st2 {
      padding-top: 27px;
    }
  }
  &__section:last-child {
    .common_form-name {
      font-size: 12px;
      line-height: 17px;
      font-weight: normal;
    }
  }
  & .common_form-name {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    font-family: ff(ATF);
  }
  & .common_form-ipt-container {
    width: 90%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  & .header {
    @include susy-breakpoint(bp(tablet)) {
      padding-bottom: 21px;
    }
  }
  .submit-btn {
    color: #fff;
    letter-spacing: -0.16px;
    line-height: 21px;
    font-family: ff(ATF);
    text-transform: uppercase;
    display: inline-block;
    background: #fa6600;
    padding: 12px 20px;
    margin-top: 20px;
    cursor: pointer;
  }
  .header {
    @media (max-width:768px) {
      margin: 0;
    }
  }
  @media (max-width:768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.bikeList {
    padding: 17px 0px 0px;
    max-width: 944px;
    margin: 0 auto;
    font-family: ff(ATF);
    @include susy-breakpoint(bp(tablet)) {
        padding-bottom: 5px;
    }
    &__out {
        max-width: 915px;
        margin: 0 auto;
        padding-top: 35px;
        background: #fff;
        text-align: center;
        padding-right: 20px;
        padding-left: 20px;
        @include susy-breakpoint(bp(tablet)) {
            padding-right: 143px;
            padding-left: 143px;
        }
    }
    &__title,
    &__sub,
    &-right {
        text-align: left;
    }
    &__title {
        font-size: 12px;
        line-height: 17px;
        margin-top: 16px;
        font-weight: 500;
        @include susy-breakpoint(bp(tablet)) {
            font-size: 12px;
            line-height: 16px;
        }
    }
    &__sub {
        // font-family: ff(Dor);
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0;
        padding: 11px 0 19px;
        &-right {
            // display: none;
            color: #000;
            // font: normal normal normal 12px/17px Franklin Gothic ATF;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            @include susy-breakpoint(bp(tablet)) {
                display: block;
            }
        }
        @media (max-width: 768px) {
            padding-bottom: 0;
        }
    }
    &__select {
        font-family: ff(ATF);
        font-size: 16px;
        line-height: 18px;
        padding: 15px 15px;
        background-color: c(white-c);
        color: #7e7e7e;
        position: relative;
        @include susy-clearfix();
        cursor: pointer;
        border: solid 1px #000;
        border-radius: 4px;
        & .icon-arrow-down {
            color: c(black-c);
            float: right;
            width: 16px;
        }
        &-out {
            position: relative;
            padding-top: 20px;
            @include susy-breakpoint(bp(tablet)) {
                display: none;
            }
        }
        &-list {
            background-color: c(white-c);
            color: c(black-c);
            font-family: ff(ATF);
            position: absolute;
            width: 100%;
            z-index: 2;
            display: none;
            max-height: 75vh;
            overflow-y: scroll;
            &.open {
                display: block;
            }
        }
        &-li {
            font-size: 18px;
            line-height: 55px;
            width: calc(100% - 2px);
            cursor: pointer;
            position: relative;
            & .icon {
                display: none;
                float: right;
                margin-top: 17px;
            }
            &.active {
                background-color: c(main);
                font-family: ff(Docb);
                & .icon {
                    display: block;
                }
            }
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                top: 0;
                left: 0;
            }
        }
        &.open {
            & .icon {
                transform: rotate(180deg);
            }
        }
    }
    &__container {
        padding-top: 20px;
        @include susy-clearfix();
        width: calc(100% + 20px);
        font-family: ff(ATF);
        @include susy-breakpoint(bp(tablet)) {
            padding-top: 0;
            width: calc(100% + 12px);
        }
    }
    &__card {
        float: left;
        width: calc((100% - 40px) / 2);
        margin-right: 20px;
        margin-bottom: 12px;
        position: relative;
        @include susy-breakpoint(bp(tablet)) {
            width: calc((100% - 48px) / 4);
            margin-right: 12px;
            margin-bottom: 15px;
        }
        &-outer {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                left: 0;
                top: 0;
                opacity: 0.25;
                transition: opacity 0.35s ease-in-out;
                cursor: pointer;
            }
        }
        &-title {
            position: relative;
            padding: 11px 10px 11px 7px;
            color: #fff;
            background-color: #fa6600;
            font-family: ff(ATO);
            font-size: 12px;
            line-height: 12px;
            letter-spacing: -0.11px;
            text-transform: uppercase;
            @include susy-clearfix();
            transition: all 0.35s ease-in-out;
            @include susy-breakpoint(bp(tablet)) {
                font-size: 12px;
                letter-spacing: -0.11px;
            }
            & .icon-arrow-down {
                transition: all 0.35s ease-in-out;
                color: c(white-c);
                width: 15px;
                position: absolute;
                right: 8px;
                top: 50%;
                margin-top: -9px;
            }
            .calc-width {
                width: calc(100% - 20px);
                line-height: 16px;
                text-align: left;
            }
        }
        &.others {
            & .bikeList__card-outer::after {
                background-color: #eeeceb;
            }
        }
        &.active {
            & .bikeList__card-outer::after {
                opacity: 0;
            }
            & .bikeList__card-title {
                background-color: #000;
                color: #fff;
                & .icon-arrow-down {
                    transform: rotate(180deg);
                    color: #fff;
                }
            }
        }
    }
    &__imgBox {
        background-color: c(white-c);
        padding-bottom: 81.25%;
        border: 1px solid #eeeceb;
        &:last-child {
            background-color: transparent;
        }
    }
    &__img {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        padding: 2px 2px;
        object-fit: cover;
    }
    &__info {
        margin-bottom: 15px;
        display: flex;
        // padding: 10px 13px 23px 20px;
        color: c(black-c);
        background-color: c(white-c);
        @include susy-clearfix();
        // border-top: solid 8px c(main);
        float: left;
        width: calc(100% - 20px);
        position: relative;
        transform-origin: left top;
        animation: bigger 0.45s ease-in-out;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        @include susy-breakpoint(bp(tablet)) {
            width: calc(100% - 12px);
            // padding: 10px 10px 10px 16px;
        }
        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: -15px;
        //   left: 0;
        //   width: 0;
        //   height: 0;
        //   border-left: 7px solid transparent;
        //   border-right: 7px solid transparent;
        //   border-bottom: 8px solid c(main);
        // }
        &.first {
            &::before {
                left: 20%;
                @include susy-breakpoint(bp(tablet)) {
                    left: 11%;
                }
            }
        }
        &.second {
            &::before {
                left: 75%;
                @include susy-breakpoint(bp(tablet)) {
                    left: 37%;
                }
            }
        }
        &.third {
            &::before {
                left: 20%;
                @include susy-breakpoint(bp(tablet)) {
                    left: auto;
                    right: 37%;
                }
            }
        }
        &.forth {
            &::before {
                left: 75%;
                @include susy-breakpoint(bp(tablet)) {
                    left: auto;
                    right: 11%;
                }
            }
        }
        &-close {
            float: right;
            position: relative;
            cursor: pointer;
            &::after {
                content: "";
                position: absolute;
                width: 24px;
                height: 24px;
                top: 0;
                left: 0;
                cursor: pointer;
            }
            & .icon-close {
                width: 24px;
                height: 24px;
            }
        }
        &-img {
            display: none;
            object-fit: contain;
            @include susy-breakpoint(bp(tablet)) {
                display: block;
                float: left;
                margin-top: 20px;
                margin-bottom: 20px;
                // padding-bottom: 20px;
            }
        }
        &-box {
            padding-top: 15px;
            width: 100%;
            @include susy-breakpoint(bp(tablet)) {
                padding-left: 20px;
                float: left;
                width: calc(100% - 174px);
                @include susy-clearfix();
            }
        }
        &-title {
            font: {
                weight: 500;
                size: 16px;
                family: ff(ATF);
            }
            line-height: 21px;
            letter-spacing: -0.4px;
            text-align: left;
            @include susy-breakpoint(bp(tablet)) {
                // font-size: 24px;
                // line-height: 42px;
            }
            & sup {
                font-size: 14px;
            }
        }
        &-text {
            font-family: ff(ATF);
            font-size: 12px;
            line-height: 17px;
            text-align: left;
            letter-spacing: 0px;
            padding-top: 12px;
            // padding-bottom: 20px;
            @include susy-breakpoint(bp(tablet)) {
                // max-width: 313px;
            }
        }
        &-btn {
            text-align: left;
            // font: normal normal normal 16px/21px ITC Franklin Gothic Std;
            // padding-top: 10px;
            padding: 20px 0;
            font: {
                size: 16px;
                family: ff(ATF)
            }
            line-height: 21px;
            letter-spacing: -0.16px;
            color: #000000;
            text-transform: uppercase;
            @include susy-breakpoint(bp(tablet)) {
                // float: left;
            }
            & .common-cta_btn {
                // float: left;
                // min-width: 162.5px;
                @include susy-breakpoint(bp(tablet)) {
                    // min-width: 211px;
                }
            }
            @media (max-width:769px) {
                padding-bottom: 20px;
            }
        }
        .bikeList__findDealer {
            padding: 12px 27px;
            min-height: unset;
            border: 1.5px solid #000000;
            background: transparent;
            letter-spacing: -0.16px;
        }
        @media (max-width: 769px) {
            .bikeList__info-box {
                width: 100%;
            }
        }
    }
}

.hidden {
    display: none !important;
}
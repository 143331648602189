.header {
  max-width: 915px;
  margin: 0 auto;
  padding-top: 50px;
  background: #fff;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  font-family: ff(ATF);
  @include susy-breakpoint(bp(tablet)) {
    padding-right: 143px;
    padding-left: 143px;
  }
  @media (max-width: 768px) {
    margin: 0 20px;
  }
  &__title {
    letter-spacing: 0px;
    font-size: 37px;
    line-height: 37px;
    font-weight: bold;
    @include susy-breakpoint(bp(tablet)) {
      max-width: none;
      text-align: center;
    }
  }

  &__text {
    // font-family: ff(Dor);
    font-size: 16px;
    line-height: 21px;
    padding-top: 15px;
    text-align: center;

    @include susy-breakpoint(bp(tablet)) {
      max-width: none;
      font-size: 16px;
      line-height: 21px;
      padding-top: 15px;
    }
  }

  &__steps {
    position: relative;
    @include susy-breakpoint(bp(tablet)) {
      float: left;
      width: 33.33%;
      text-align: center;
    }
    @media (max-width: 960px) {
      padding-bottom: 20px;
    }
    &-out {
      max-width: 915px;
      margin: 0 auto;
      padding-top: 30px;
      background: #fff;
      text-align: center;
      padding-right: 20px;
      padding-left: 20px;
      font-family: ff(ATF);
      @include susy-breakpoint(bp(tablet)) {
        padding-right: 237px;
        padding-left: 237px;
      }
      @media (max-width: 960px) {
        margin: 0 20px;
        text-align: left;
      }
    }

    &-box {
      // padding: 30px 20px 0 20px;
      @include susy-clearfix();

      @include susy-breakpoint(bp(tablet)) {
        max-width: 896px;
        // margin: 30px auto 0;
      }
    }

    &-circle {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: c(black-c);
      margin-right: 15px;
      background-color: c(main);
      border-radius: 50%;
      font-size: 14px;
      font-family: ff(ATF);
      font-weight: 600;
      z-index: 1;
      color: #fff;
      @include susy-breakpoint(bp(tablet)) {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
      }
    }

    &-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      @include susy-breakpoint(bp(tablet)) {
        padding-top: 12px;
        display: block;
      }
    }

    &-par {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 50%;
      left: 12px;
      bottom: 0;
      background-color: c(main);

      @include susy-breakpoint(bp(tablet)) {
        height: 2px;
        width: 50%;
        top: 15px;
        left: 50%;
      }
    }

    &::after {
      @include susy-breakpoint(bp(tablet)) {
        content: "";
        position: absolute;
        height: 2px;
        width: 50%;
        top: 15px;
        left: 0;
        background-color: c(main);
      }
    }

    &:last-child::before {
      display: none;
    }

    &:first-child::after {
      display: none;
    }
    @media (max-width: 768px) {
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.icon {
  display: inline-block;
  stroke-width: 0;
  stroke: #000;
  fill: currentColor;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.icon-chevron-left {
  transform: rotateY(180deg);
}

.icon-hd-logo {
  width: 44px;
  height: 36px;
}

.black__icon {
  & .icon-hd-logo {
    @include susy-breakpoint(bp(tablet)) {
      filter: brightness(10) invert(1);
    }
  }
}

.icon-twitter {
  width: 25px;
  height: 20px;
}

.icon-instagram {
  width: 22px;
  height: 22px;
}

.icon-facebook {
  width: 13px;
  height: 25px;
}

.icon-linkedin {
  position: relative;
  top: -.2em;
}

.icon-youtube {
  width: 24px;
  height: 24px;
}

.cs_CZ,
.hu_HU,
.pl_PL {
  & .header__text,
  & .header__steps-text,
  & .header__steps-par,
  & .bikeList__sub,
  & .googleMap__textBox-text,
  & .googleMap__mapBox-text,
  & .formModel__textbox-text,
  & .formModel,
  & .common_form-place-hold,
  & .common-cta_btn,
  & .popup,
  & input:placeholder-shown,
  & input:placeholder-shown,
  & .googleMap__detail-title,
  & .googleMap__detail-address,
  & .googleMap__distance,
  & .hero__textBox,
  & .shareBar__title,
  & .bikeInfoCard__dealer-title,
  & .bikeInfoCard__dealer,
  & .noFound__title,
  & .noFound__text,
  & .bikeList__info-text,
  & .footer_links-container a,
  & .googleMap__dealerCard-text,
  & .googleMap__textBox-text,
  & .googleMap__textBox-changeText {
    font-family: ff(ATF);
  }

  & .header__title,
  & .bikeList__title,
  & .googleMap__textBox-title,
  & .formModel__textbox-title,
  & .hero__title,
  & .googleMap__dealerCard-title,
  & .googleMap__dealerBox-title,
  & .googleMap__dealerBox-change {
    font-family: ff(ATF);
  }

  & .common-cta_btn {
    font-weight: bold;
  }
}

.ru_RU,
.el_GR {
  .header__title {
    font-weight: bold;
  }
  .header__steps-text,
  .bikeList__title,
  .googleMap__textBox-title,
  .formModel__textbox-title,
  input::placeholder,
  .shareBar__title,
  .bikeInfoCard__dealer-title,
  .bikeInfoCard__body-title,
  .common_form-name,
  .googleMap__dealerCard-title,
  .googleMap__dealerBox-change,.common-cta_btn,.googleMap__detail-title {
    font-weight: bold;
  }
  .expanding {
    font-weight: bold;
  }
  .bikeInfoCard__body-link {
    font-weight: bold;
  }
}
